import { Copyright } from "@mui/icons-material";
import { Link } from "@mui/material";
import React from "react";

function Footer() {

	return (
		<>




			<footer className="footer-bottom-area fun-blue-bg" >
				<div className="container">
					<div className="pe-1 co">Copyright&nbsp;<Copyright />&nbsp;2023&nbsp;&nbsp;All Rights Reserved</div>
					<div className="d-flex single-widget-bottom">
						<div className="pe-2">
							{/* <i className='bx bxl-twitter'></i> */}
						</div>
						<div className="pe-2">
							{/* <i className='bx bxl-facebook'></i> */}
						</div>
						<div className="pe-2">
							{/* <i className='bx bxl-instagram'></i>  */}
						</div>

						<div className="pe-2">
							{/* <i className='bx bxl-linkedin'></i> */}
						</div>
						<div className="pe-2">
							{/* <i className='bx bxl-youtube'></i> */}
						</div>
					</div>

				</div>
			</footer>

		</>
	)

}

export default Footer;